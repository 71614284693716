import {ProductType} from "../../../types";
import {useAuthSlice} from "../../../slices/useAuth";
import React, {useEffect, useState} from "react";
import styles from './BasketProduct.module.css';
import crossIcon from '../../../img/cross-circle.png';
import {Link} from "react-router-dom";
import {debounce} from "lodash";

export function roundToNearest(num: number, decimals = 2) {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
}

const BasketProductItem = ({product}: { product: { id: ProductType, quantity: number } }) => {
    const {
        addProductToCart,
        // changeProductQuantity,
        removeProductFromCart,
        authState: {category, isLoading}
    } = useAuthSlice()
    const [quantity, setQuatity] = useState<number | string>(product.quantity)
    const [debounceTrigger,setDebouncetrigger]=useState<boolean>(true)
    const price = product.id !== undefined && product.id.prices.find(({crmId}) => crmId && crmId === category)?.value
    const totalPrice = price && price * +quantity
    const debouncedAddProductToCart = debounce(() => setDebouncetrigger(p=>!p), 2000);
    // useEffect(() => {
    //     // changeProductQuantity({id: product.id._id, quantity})
    //     addProductToCart({id: product.id._id, quantity: +quantity})
    //     // eslint-disable-next-line
    // }, [product.id._id, quantity]);
    useEffect(() => {
        // changeProductQuantity({id: product.id._id, quantity})
        addProductToCart({id: product.id._id, quantity: +quantity})
        // eslint-disable-next-line
    }, [debounceTrigger]);

  useEffect(() => {
    if (quantity < 0) {
      setQuatity(0);
    }
  }, [quantity]);


    return (
        <div className={styles.basket__item}>
            <div className={styles.basket__itemWrap}>
                <button onClick={() => removeProductFromCart({id: product.id._id})} className={styles.delete__btn}>
                    <img className={styles.cart__icon} src={crossIcon} alt="cart"/>
                </button>

                <div className={styles.basket__picture}>
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/${product.id?.image ? product.id?.image : 'media/products/woocommerce-placeholder.png'}`}
                        alt={product.id?.name}
                    />
                </div>
                <div className={styles.item__block}>
                    <Link to={`/catalog/product/${product.id._id}`}
                          className={styles.item__name}>{product.id.name}</Link>
                    {/*  <p className={styles.item__name}>{product.id.name}</p>*/}
                    <p className={styles.item__price}>{price} грн</p>
                    <p className={styles.item__text}>Артикул: {product.id.article}</p>
                    {/*<p className={styles.item__text}>Залишок товару: {product.id.quantity}</p>*/}
                </div>
            </div>
            <div className={styles.counterWrap}>
                <div className={styles.item__quantity}>
                    <div className={styles.cart__total}>
                        <button
                            className='btn__minus'
                            disabled={isLoading || product.id.quantity === 0 || quantity === 0}
                            onClick={() => {
                                debouncedAddProductToCart()
                                setQuatity(p => p && p === 0 ? p : +p - 1)
                            }}>-
                        </button>
                        <input disabled={isLoading || product.id.quantity === 0} min={0}
                            // max={product.id.quantity}
                               onFocus={() => setQuatity("")}
                               onChange={(e) => {
                                   debouncedAddProductToCart()
                                   setQuatity(+e.target.value)
                               }}
                               value={quantity} type="number"/>
                        <button
                            className='btn__plus'
                            disabled={isLoading || product.id.quantity === 0}
                            onClick={() => {
                                debouncedAddProductToCart()
                                setQuatity(p => +p + 1)
                            }}>+
                        </button>
                    </div>
                </div>
                <div className={styles.item__total__price}>
                    <span>Усього</span>
                    <p>{totalPrice && roundToNearest(totalPrice, 2)} грн.</p>
                </div>
            </div>
        </div>
    )
}
export default BasketProductItem