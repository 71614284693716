import React, {useState} from 'react';
import {HeaderPage} from "../../components/Header/HeaderPage";
import {useLocation, useParams} from "react-router-dom";
import Category from "../../components/Category/Category";
import FilterSort from "../../components/FilterSort/FilterSort";
import ProductList from "../../components/Product/ProductList";
import {Footer} from "../../components/Footer/Footer";

const Catalog: React.FC = () => {
    const params = useParams();
    const [activeCategory, setActiveCategory] = useState(null);
    const [count, setCount] = useState<number>(0);
    const [isTile, setIsTile] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    const [sortValue, setSortValue] = useState<string>('news');
    const path = useLocation().pathname.split('/')[1];
    let tag: string | undefined,
        category: string | undefined,
        subCategory: string | undefined,
        subSubCategory: string | undefined
    if (path === 'categories') {
        tag = params.tag
    } else if (path === 'catalog') {
        category = params.category
        subCategory = params.subCategory
        subSubCategory = params.subSubCategory
    }
    const dynamicTitle = () => {
        if (tag === 'news') return 'Новинки'
        if (tag === 'discount') return 'Знижки'
        if (tag === 'arrival') return 'Надходження'
        if (category && !subCategory && !subSubCategory) return `${category.replace('_', ' ')}`
        if (category && subCategory && !subSubCategory) return `${category.replace('_', ' ')} - ${subCategory.replace('_', ' ')}`
        if (category && subCategory && subSubCategory) return `${category.replace('_', ' ')} - ${subCategory.replace('_', ' ')} - ${subSubCategory.replace('_', ' ')}`
    }
    const toggleCategory = (category: any) => {
        if (activeCategory === category) {
            setActiveCategory(null);
        } else {
            setActiveCategory(category);
        }
    };


    return (
        <>
            <HeaderPage/>
            <div className="container">
                <div className="short-title"></div>
                <div className="catalog-wrapper">
                    <div className="catalog-side">
                        <Category setPage={setPage}/>
                        {/*<Filter*/}
                        {/*    data={filterItemNews}*/}
                        {/*    isShort={false}*/}
                        {/*/>*/}
                        {/*<Filter*/}
                        {/*    data={filterItemProduct}*/}
                        {/*    isShort={false}*/}
                        {/*/>*/}
                    </div>
                    <div className="catalog-content">
                        <div className="catalog-head">
                            <p><b>{dynamicTitle()}</b> ({count} товарів)</p>
                            <FilterSort setPage={setPage} sortValue={sortValue} setSortValue={setSortValue}/>
                            <button onClick={() => setIsTile(!isTile)}>
                                {!isTile
                                    ? <svg viewBox="0 0 512 512" width="22" height="22"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z"/>
                                    </svg>
                                    : <svg viewBox="0 0 512 512" width="22" height="22"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                        <div className="filter-mobile">
                            <div className="filter-mobile-list">
                                <button onClick={() => toggleCategory('filters')}>
                                    <svg fill="none" width="28px" height="28px" role="img" stroke="#000"
                                         strokeLinecap="square" strokeLinejoin="miter" strokeWidth="1"
                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12.261L4.028 3.972h16L14 12.329V17l-4 3z"/>
                                    </svg>
                                    Фільтри
                                </button>
                                <button onClick={() => toggleCategory('categories')}>
                                    <svg width="22" height="22" viewBox="0 0 512 512">
                                        <g>
                                            <g>
                                                <path
                                                    d="M400.5,0.2H111.5c-37.8,0-68.8,31-68.8,68.8v374c0,37.8,31,68.8,68.8,68.8h289.1c37.8,0,68.8-31,68.8-68.8    V69C469.3,31.2,438.4,0.2,400.5,0.2z M422.5,404.7c0,29.8-24.4,54.1-54.1,54.1H141c-29.8,0-54.1-24.4-54.1-54.1V110.5    c0-29.8,24.4-54.1,54.1-54.1h227.4c29.8,0,54.1,24.4,54.1,54.1V404.7z"
                                                    fill="#303A3F"/>
                                            </g>
                                            <g>
                                                <path
                                                    d="M388.1,253.7v5.9c0,11.3-9.2,20.5-20.5,20.5H211.4c-11.3,0-20.5-9.2-20.5-20.5v-5.9    c0-11.3,9.2-20.5,20.5-20.5h156.2C378.9,233.2,388.1,242.4,388.1,253.7z"
                                                    fill="#303A3F"/>
                                                <path
                                                    d="M388.1,343.1v5.9c0,11.3-9.2,20.5-20.5,20.5H211.4c-11.3,0-20.5-9.2-20.5-20.5v-5.9    c0-11.3,9.2-20.5,20.5-20.5h156.2C378.9,322.7,388.1,331.9,388.1,343.1z"
                                                    fill="#303A3F"/>
                                                <path
                                                    d="M388.1,162.5v5.9c0,11.3-9.2,20.5-20.5,20.5H211.4c-11.3,0-20.5-9.2-20.5-20.5v-5.9    c0-11.3,9.2-20.5,20.5-20.5h156.2C378.9,142,388.1,151.2,388.1,162.5z"
                                                    fill="#303A3F"/>
                                                <circle cx="147.8" cy="165.8" fill="#303A3F" r="23.8"/>
                                                <circle cx="147.8" cy="253.6" fill="#303A3F" r="23.8"/>
                                                <circle cx="147.8" cy="346.1" fill="#303A3F" r="23.8"/>
                                            </g>
                                        </g>
                                    </svg>
                                    Категорії
                                </button>
                                <button onClick={() => toggleCategory('sorting')}>
                                    <svg height="25" viewBox="0 0 21 21" width="25" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round"
                                           strokeLinejoin="round" transform="translate(2 4)">
                                            <path d="m8.5 8.5 4 4.107 4-4.107"/>
                                            <path d="m8.5 4.5-4-4-4 3.997"/>
                                            <path d="m4.5.5v12"/>
                                            <path d="m12.5.5v12"/>
                                        </g>
                                    </svg>
                                    Сортування
                                </button>
                            </div>
                            {/*{activeCategory === 'filters' &&*/}
                            {/*    <Filter*/}
                            {/*        data={filterItemProduct}*/}
                            {/*        isShort={true}*/}
                            {/*    />*/}
                            {/*}*/}

                            {activeCategory === 'categories' && <Category setPage={setPage} />}

                            {activeCategory === 'sorting' && <FilterSort setPage={setPage} sortValue={sortValue} setSortValue={setSortValue} />}

                        </div>

                        <ProductList page={page} isTile={isTile} setPage={setPage} sortValue={sortValue} setCount={setCount} />
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
};

export default Catalog;
