
import React from 'react';
import '../../../styles/common.css'
import Catalog from "./Catalog";

const HomePage: React.FC = () => {
  return (
    <>
      <Catalog />
      {/*<BrandsSlider />*/}
    </>
  )
}

export { HomePage }
